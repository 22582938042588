import { Component, OnDestroy, OnInit } from '@angular/core';
import * as $ from 'jquery';
import { MessagingHandlerService } from '../services/messaging-handler.service';
import { UnreadMessagesService } from '../services/unread-msgs.service';
import { CookieService } from 'ngx-cookie-service';
import { takeUntil, takeWhile } from 'rxjs/operators';
import { Observable, Subject } from 'rxjs';

import { trigger, state, style, animate, transition } from '@angular/animations';
import { State, ewOpenOnChat, getActiveTab, getContactUsUrl, getFaqUrl, getEWStatus } from '../store/reducers';
import { Store } from '@ngrx/store';
import { getActiveChatStatus, getUnreadMessageCount } from '../store/selectors/chat.selectors';
import * as ChatActions from '../store/actions/chat.actions';
import * as TabsActions from '../store/actions/tabs.actions';
import * as GeneralActions from '../store/actions/general.actions';
import { getContactUsId, getContactUsError } from '../store/selectors/contact.selectors';

@Component({
  selector: 'app-tabs',
  templateUrl: './tabs.component.html',
  styleUrls: ['./tabs.component.scss'],
  animations: [
    trigger('unreadMsgs', [
      state(
        'on',
        style({
          backgroundColor: '#e87502',
        })
      ),
      state(
        'off',
        style({
          backgroundColor: '#ededed',
        })
      ),
      transition('on=>off', [animate('1s 1300ms ease-out')]),
      transition('off=>on', [animate('1s 1300ms ease-in')]),
    ]),
  ],
})
/**
 * @param  {Store<State>} privatestore
 * @param  {MessagingHandlerService} privatemessageHandlerService
 * @param  {CookieService} privatecookieService
 * @param  {UnreadMessagesService} privateunreadMsgsService
 */
export class TabsComponent implements OnInit, OnDestroy {
  constructor(
    private store: Store<State>,
    private messageHandlerService: MessagingHandlerService,
    private cookieService: CookieService,
    private unreadMsgsService: UnreadMessagesService
  ) {}
  /**
   * Active tab (observable)
   *
   * @type {Observable<string>}
   */
  activeTab$: Observable<string>;

  /**
   * Gets the number of unread messages
   * 
   * @type {Observable<number>}
   */
  unreadMsgCount$: Observable<number>;

  /**
   * DEPRECATED
   *
   * @type {Observable<string>}
   */
  currentUrl$: Observable<string>;

  /**
   * Contact Us tab ID from OKA
   *
   * @type {Observable<string>}
   */
  contactUsId$: Observable<string>;

  /**
   *  Contact Us Page XHR request failure message
   */
  contactUsError$: Observable<string>;

  /**
   * Active chat status (observable)
   */
  activeChatStatus$;

  /**
   * Active chat status (subscribed value)
   * @type {boolean}
   */
  activeChatStatus: boolean;

   /**
   * NOT IN CURRENT  State functionality
   *
   * @type {*}
   */
  hostState: any;
  
  /**
   * Current Tab name
   */
  tabName;

  /**
   * DEPRECATED: Contact Us tab URL when accessing PHP pages
   */
  contactUsUrl$;

  /**
   * FAQ tab URL
   */
  faqUrl$;

  /** DEPRECATED?: Validate */
  faqUrlAvail = false;

  /**
   *
   * Indicates if EW is Open and active tab is Chat
   */
  ewOpenOnChat$: Observable<boolean>;

  /**
   * String value of ewOpenOnChat$
   */
  ewOpenOnChat;

  /**
   * Number of unread messages
   */
  messageCount;

  

  /**
   * Used to unsubscribe from subscriptions on ngDestroy
   *
   * @type {Subject<boolean>}
   */
  unsubscribe$: Subject<boolean> = new Subject();

  ngOnInit(): void {
    this.store.dispatch(GeneralActions.setEnvironmentType({ envType: this.getEnvironmentType() }));
    this.contactUsUrl$ = this.store.select(getContactUsUrl);
    this.contactUsId$ = this.store.select(getContactUsId);
    this.contactUsError$ = this.store.select(getContactUsError);
    this.faqUrl$ = this.store.select(getFaqUrl);
    this.activeTab$ = this.store.select(getActiveTab);
    this.activeChatStatus$ = this.store.select(getActiveChatStatus);
    this.unreadMsgCount$ = this.store.select(getUnreadMessageCount);
    this.ewOpenOnChat$ = this.store.select(ewOpenOnChat);
    this.unreadMsgCount$.pipe(takeUntil(this.unsubscribe$)).subscribe((data) => {
      this.messageCount = data;
    });
    this.activeTab$.pipe(takeUntil(this.unsubscribe$)).subscribe((tab) => (this.tabName = tab));
    this.activeChatStatus$.pipe(takeUntil(this.unsubscribe$)).subscribe((status) => (this.activeChatStatus = status));
    this.ewOpenOnChat$.pipe(takeUntil(this.unsubscribe$)).subscribe((data) => { this.ewOpenOnChat = data; if (data) { this.store.dispatch(ChatActions.clearUnreadMessages()) } });

    this.faqUrl$.pipe(takeWhile((url) => url == '', true)).subscribe((val) => {
      this.faqUrlAvail = true;
    });
    parent.postMessage('readyForUrls', '*');
    if (this.activeChatStatus === true) {
      this.loadTab('chat');
    }
    /**
     * Unread Messages when functionality
     */
    window.addEventListener('AddNewUnreadMessage', (e) => {
      if (!this.ewOpenOnChat) {
        this.incrementMessages();
        if (this.messageCount > 0 && (this.tabName !== 'chat' || this.ewOpenOnChat == false)) {
          if (this.unreadMsgsService.chatBlinkTimer === '') {
            this.unreadMsgsService.chatBlinkTimer = setInterval(() => {
              this.unreadMsgsService.toggleFunc();
            }, 2000);
          }
          parent.postMessage({ name: 'genUnreadMsg', messageCount: this.messageCount }, '*');
          // console.log(`Would show ${this.messageCount}`);
        }
      }
    });
    parent.postMessage('c2e_ew_loaded', '*');
  }

  /**
   * Angular ngAfterContentInit
   */
  ngAfterContentInit(): void {
    // This will post chat count to outer frame and dispaly unread msgs on page reload or navigatin where application reloads
    if (!this.ewOpenOnChat && this.messageCount > 0 && this.activeChatStatus === true ) {
      parent.postMessage({ name: 'genUnreadMsg', messageCount: this.messageCount }, '*');
    }
  }

  /**
   * Angular ngOnDestroy
   */
  ngOnDestroy(): void {
    this.unsubscribe$.next(true);
    this.unsubscribe$.complete();
  }

  /**
   * Switches to named tab by triggering changeTabs action
   *
   * See {@link ../miscellaneous/variables.html#changeTabs|changeTabs Action} and {@link ../injectables/TabsEffects.html#getTab$|getTab$ Effect }
   * @param  {string} tabName
   * @returns void
   */
  loadTab(tabName: string): void {
    this.store.dispatch(TabsActions.changeTabs({ id: tabName }));
    if (tabName === 'chat'){
      this.unreadMsgsService.stopTimerJob();
    }
    if(tabName === 'contact') {
      this.resetSkillName();
    }
  }

  getEnvironmentType(): string {
    let output;
    window.location.hostname === 'support.thehartford.com' ? (output = 'prod') : (output = 'tst');
    return output;
  }

  /**
   * Unread Messages functionality
   *
   * @date 2022-1-21
   * @memberof TabsComponent
   */
  incrementMessages(): void {
    this.store.dispatch(ChatActions.incrementUnreadMessages());
  }

  resetSkillName() {
    let lob = sessionStorage['c2e_lob'];

    switch(lob) {
      case 'obsc':
        JSON.parse(sessionStorage.getItem('BI_SC_Web_Questions')) === true ? sessionStorage.setItem('BI_SC_Web_Questions', 'true') : sessionStorage.setItem('BI_SC_Web_Questions', 'false');
        JSON.parse(sessionStorage.getItem('BI_SC_Policy_Billing')) === true ? sessionStorage.setItem('BI_SC_Policy_Billing', 'true') : sessionStorage.setItem('BI_SC_Policy_Billing', 'false');
        JSON.parse(sessionStorage.getItem('BI_SC_COI')) === true ? sessionStorage.setItem('BI_SC_COI', 'true') : sessionStorage.setItem('BI_SC_COI', 'false');
        JSON.parse(sessionStorage.getItem('BI_SC_Audit')) === true ? sessionStorage.setItem('BI_SC_Audit', 'true') : sessionStorage.setItem('BI_SC_Audit', 'false');
        break;
      case 'ebc':
        JSON.parse(sessionStorage.getItem('Technical_Support_-_AIS')) === true ? sessionStorage.setItem('Technical_Support_-_AIS', 'true') : sessionStorage.setItem('Technical_Support_-_AIS', 'false');
        JSON.parse(sessionStorage.getItem('PL_Agency_Service_-_Home')) === true ? sessionStorage.setItem('PL_Agency_Service_-_Home', 'true') : sessionStorage.setItem('PL_Agency_Service_-_Home', 'false');
        JSON.parse(sessionStorage.getItem('BI_Service')) === true ? sessionStorage.setItem('BI_Service', 'true') : sessionStorage.setItem('BI_Service', 'false');
        JSON.parse(sessionStorage.getItem('BI_Audit')) === true ? sessionStorage.setItem('BI_Audit', 'true') : sessionStorage.setItem('BI_Audit', 'false');
        JSON.parse(sessionStorage.getItem('Download_AMS_Support_-_AIS')) === true ? sessionStorage.setItem('Download_AMS_Support_-_AIS', 'true') : sessionStorage.setItem('Download_AMS_Support_-_AIS', 'false');
        break;
      case 'pl':
        JSON.parse(sessionStorage.getItem('PL_Service')) === true ? sessionStorage.setItem('PL_Service', 'true') : sessionStorage.setItem('PL_Service', 'false');
        JSON.parse(sessionStorage.getItem('PL_Sales_-_Auto')) === true ? sessionStorage.setItem('PL_Sales_-_Auto', 'true') : sessionStorage.setItem('PL_Sales_-_Auto', 'false');
        JSON.parse(sessionStorage.getItem('PL_Sales_-_Home')) === true ? sessionStorage.setItem('PL_Sales_-_Home', 'true') : sessionStorage.setItem('PL_Sales_-_Home', 'false');  
        break;
      case 'oqp':
        JSON.parse(sessionStorage.getItem('SC_Direct_Sales')) === true ? sessionStorage.setItem('SC_Direct_Sales', 'true') : sessionStorage.setItem('SC_Direct_Sales', 'false');
        break;
      default: break;
    }
  }
}
